export const getTopicUrlsToIntercept: Array<string> = [
  'field-configurations',
  // 'fieldconfiguration',
  'melos',
  'malos',
  'chapters',
  'contacts',
  'filters',
  'not-reduced-melocutoffs',
  'reduced-melocutoffs',
  'bulk-notifications',
  'links',
];
