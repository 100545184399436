import { Component } from '@angular/core';
import { FilterMatchMode, PrimeNGConfig } from 'primeng/api';
import { primengConfigTranslation } from '../../constants/primeng-config-translation.const';
import { MsalBroadcastService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(
    private readonly _config: PrimeNGConfig,
    // DO NOT REMOVE THIS INJECTION
    // FIXME: This is required for redirection to work somehow, please don't ask
    // FIXME: me to explain this as i literally do not know how this could
    // FIXME: change anything.
    private readonly _msalBroadcastService: MsalBroadcastService
  ) {
    this._config.setTranslation(primengConfigTranslation);
    this._config.filterMatchModeOptions = {
      text: [FilterMatchMode.CONTAINS],
      numeric: [
        FilterMatchMode.EQUALS,
        FilterMatchMode.NOT_EQUALS,
        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        FilterMatchMode.GREATER_THAN,
        FilterMatchMode.LESS_THAN,
      ],
      date: [
        FilterMatchMode.DATE_IS,
        FilterMatchMode.DATE_IS_NOT,
        FilterMatchMode.DATE_BEFORE,
        FilterMatchMode.DATE_AFTER,
      ],
    };
  }
}
