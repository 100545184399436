// TODO: find way to typifiy lazyloading
/* eslint-disable @typescript-eslint/typedef */
import {
  mapToCanActivate,
  mapToCanActivateChild,
  RouterModule,
  Routes,
} from '@angular/router';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import {
  RlmAppCrashComponent,
  rlmFeatureFlagResolver,
  RlmMaintenancePageComponent,
  RlmPortalTopicsSetGuard,
  RlmRoutingPath,
  RlmUserTopicsSetGuard,
} from '@wgi/rlm/common';

const routes: Routes = [
  {
    path: RlmRoutingPath.MAIN,
    loadChildren: () => import('./main/main.module').then(m => m.MainModule),
    canActivate: mapToCanActivate([
      MsalGuard,
      RlmPortalTopicsSetGuard,
      RlmUserTopicsSetGuard,
    ]),
    canActivateChild: mapToCanActivateChild([MsalGuard]),
    runGuardsAndResolvers: 'always',
    resolve: {
      featureFlags: rlmFeatureFlagResolver,
    },
  },
  {
    path: RlmRoutingPath.LOGIN,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'error',
    component: RlmAppCrashComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: '',
    redirectTo: RlmRoutingPath.MAIN,
    pathMatch: 'full',
  },
  {
    path: 'maintenance',
    component: RlmMaintenancePageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
