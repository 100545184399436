export const primengConfigTranslation: { [p: string]: string | Array<string> } =
  {
    dateIs: 'Gleich',
    dateAfter: 'Danach',
    dateBefore: 'Davor',
    dateIsNot: 'Ungleich',
    dateFormat: 'dd.mm.yy',
    startsWith: 'Beginnt mit',
    contains: 'Enthält',
    notContains: 'Enthält nicht',
    endsWith: 'Endet mit',
    equals: 'Gleich',
    notEquals: 'Ungleich',
    noFilter: 'Kein Filter',
    lt: 'Kleiner als',
    lte: 'Bis',
    gt: 'Größer als',
    gte: 'Ab',
    is: 'Ist',
    isNot: 'Ist nicht',
    before: 'Vor',
    after: 'Nach',
    apply: 'Anwenden',
    matchAll: 'UND-Verknüpfung',
    matchAny: 'ODER-Verknüpfung',
    addRule: 'Regel hinzufügen',
    removeRule: 'Regel entfernen',
    accept: 'Ja',
    reject: 'Nein',
    choose: 'Wählen',
    upload: 'Hochladen',
    cancel: 'Abbrechen',
    dayNames: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
    today: 'Heute',
    clear: 'Löschen',
    weekHeader: 'KW',
  };
